import React, { useState, createContext} from 'react';

export const ModalContext = createContext(null);

export const ModalProvider = ({children}) => {

    const showModal = (component, config = {}) => {
        document.body.classList.add('modal-open');

        const newState = {
            ...modalState,
            component: component,
            config: config
        };
        setModalState(newState);
    };

    const closeModal = () => {
        document.body.classList.remove('modal-open');
        
        const newState = {
            ...modalState,
            component: null,
            config: {}
        };
        setModalState(newState);
    };

    const backdropClose = (e) => {
        if (e) {
            if (e.target.className !== 'ss-modal') {
                return;
            } else {
                closeModal();
            }
        }
    }

    const [modalState, setModalState] = useState({
        component: null,
        config: {},
        showModal: showModal,
        closeModal: closeModal,
        backdropClose: backdropClose
    });

    return(
        <ModalContext.Provider value={modalState}>
            {children}
        </ModalContext.Provider>
    )
}