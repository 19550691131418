import React from 'react';

import UserScriptDataSource from './userScriptDataSource';

const UserScriptDataSourceContext = React.createContext(null);

const UserScriptDataSourceProvider = ({children}) => { 
	return(
        <UserScriptDataSourceContext.Provider value={UserScriptDataSource()}>
            {children}
        </UserScriptDataSourceContext.Provider>
    )
}

export { UserScriptDataSourceContext, UserScriptDataSourceProvider };