import React, {Suspense, lazy} from 'react';

import {Route, Switch} from 'react-router-dom';
import Analytics from '../../common/analytics';
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.scss';

import * as ROUTES from '../../constants/routes';
import ContextProvider from '../../common/contextProvider'
import {ModalRoot} from '../../common/modal';
import {LoadingRoot, LoadingComponent} from '../../common/loadingScreen';
import {AlertBannerRoot} from '../../common/alertBanner/index'

import NavigationBar from '../../components/navigation';
import Footer from '../../components/footer';
import LandingPage from '../landing';

const SignUpPage = lazy(() => import("../signUp"));
const SignInPage  = lazy(() => import('../signIn'));
const PasswordResetPage = lazy(() => import('../passwordReset'));
const AccountPage = lazy(() => import('../account'));
const ProfilePage = lazy(() => import('../profile'));
//import DashboardPage from '../dashboard';
const ScriptsPage = lazy(() => import('../scripts'));
const ScriptEditPage = lazy(() => import('../script_edit_page'));
const PitchSheet = lazy(() => import('../pitchSheet'));
const SearchPage = lazy(() => import('../search_page'));
const EventsPage = lazy(() => import('../events_page'));
const Privacy = lazy(() => import('../policy/privacy'));
const Terms = lazy(() => import('../policy/terms'));
const Faq = lazy(() => import('../terciary/faq'));
const NotFound404 = lazy(() => import('../notFound404'));


const App = () => {
    return(
        <ContextProvider>
            <Analytics/>
            <div className="app-body">
                <div className="container-fluid">
                    <div className="app-content pt-5">
                        <NavigationBar />
                        <Suspense fallback={LoadingComponent()}>
                            <Switch>
                                <Route exact path={ROUTES.LANDING} component={LandingPage} />
                                <Route path={ROUTES.ACCOUNT} component={AccountPage} />
                                <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
                                <Route path={ROUTES.SIGN_IN} component={SignInPage} />
                                <Route path={ROUTES.PASSWORD_RESET} component={PasswordResetPage} />
                                <Route path={ROUTES.PROFILE} component={ProfilePage} />
                                <Route path={ROUTES.SEARCH} component={SearchPage} />
                                {/* <Route path={ROUTES.EVENTS} component={EventsPage} /> */}
                                {/* <Route path={ROUTES.DASHBOARD} component={DashboardPage} /> */}
                                <Route path={ROUTES.SCRIPTEDITPAGE} component={ScriptEditPage}/>
                                <Route path={ROUTES.SCRIPTS} component={ScriptsPage} />
                                <Route path={ROUTES.PITCHSHEET} component={PitchSheet} />

                                <Route path={ROUTES.PRIVACY} component={Privacy} />
                                <Route path={ROUTES.TERMS} component={Terms} />
                                <Route path={ROUTES.FAQ} component={Faq} />

                                <Route component={NotFound404} />
                            </Switch>
                        </Suspense>
                    </div>
                    <Footer />
                </div>
            </div>
            <ModalRoot/>
            <AlertBannerRoot/>
            <LoadingRoot/>
        </ContextProvider>
    );
};

export default App;