import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './containers/app';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module'

import './assets/_variables.scss';
const dotenv = require('dotenv');
dotenv.config();
const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
};
tagManagerArgs.auth = process.env.REACT_APP_GTM_AUTH;
if(process.env.REACT_APP_GTM_AUTH !== `NULL`){
    tagManagerArgs.auth = process.env.REACT_APP_GTM_AUTH;
    tagManagerArgs.preview = process.env.REACT_APP_GTM_PREVIEW;
}
TagManager.initialize(tagManagerArgs);

ReactDOM.render(
    <App />, 
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
