import {useContext} from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";

import { FirebaseContext } from '../firebase';
import { LoadingContext } from '../loadingScreen';

//warpped in contextProvider.js 
//Call through use context AuthUserContext
const baseService = () => {
    const loadingScreen = useContext(LoadingContext);
    const firebase = useContext(FirebaseContext);
    let history = useHistory();
    
    const CALL = async (config, data) => {
        //config file should have /loading_screen: true /cacheKey: false/...
        if(config.loading_screen){
            loadingScreen.showLoadingScreen();
        }

        const options = {
            method: config.method,
            url: `${process.env.REACT_APP_SHOWRITER_API}${config.URL}`
        };

        if (config.headers) {
            options.headers = {
                Authorization: 'Bearer ' + firebase.token
            }
        }

        if (data) {
            options.data = data;
        }

        const results = new Promise( async (resolve, reject) => {
            for (let i = 0; i < 2; i++) {
                try{
                    const result = await axios(options);
                    resolve(result);
                    break;
                } catch(err) {
                    if (config.headers && i === 0){
                        let newToken = await setToken();
                        if (newToken) {
                            options.headers = {Authorization: 'Bearer ' + newToken };
                        }
                    }

                    if (i === 1 &&  err.response.status === 401) {
                        //withAuthorization will remember location for signing back in.
                        firebase.doSignOut();
                        reject(err);
                    } else if (i === 1) {
                        if(err.response.status === 404){
                            history.push('/error');
                        }
                        if (err.response.status === 403) {
                            // for future analytics when dealing with people trying to get producer scripts.
                        }
                        err.response.data.status = err.response.status;
                        reject(err.response.data)
                    }
                }
            }

            loadingScreen.closeLoadingScreen();
        });

        return results;
    }

    const setToken = () => {
        if (!firebase.auth.currentUser) {
            return null;
        }
        return new Promise( (resolve, reject) => {
            firebase.auth.currentUser.getIdToken(true)
                .then( response => {
                    firebase.token = response;
                    resolve(response);
                })      
                .catch( e => {
                    // we need to log e somewhere.
                    reject(null);
                });
        });
    }

    return {
        CALL: CALL,
        setToken: setToken
    };
}

export default baseService;
