import React from 'react';

import { AlertBannerContext, AlertBannerProvider } from './alertBannerContext';

const AlertBannerRoot = () => (
  	<AlertBannerContext.Consumer>
		{({bannerData}) => 
			{
				if (bannerData.showBanner) { 

                    let alertTypeClass = 'alert mr-1 ';
                    let prefixWord = '';

                    switch(bannerData.alertType) {
                        case 'success':
                            alertTypeClass +=  'alert-success';
                            prefixWord = 'Success:';
                            break;
                        case 'warning':
                            alertTypeClass +=  'alert-warning';
                            prefixWord = 'Warning:';
                            break;
                        case 'danger':
                            alertTypeClass +=  'alert-danger';
                            prefixWord = 'Error:';
                            break;
                        default:
                            alertTypeClass +=  'alert-info';
                            prefixWord = 'Info:';
                    }

					return(
                        <div className="fixed-top mt-5">
                            <div className="row">
                                <div className="col-12">
                                    <div className={alertTypeClass} role="alert">
                                        <strong>{prefixWord}</strong> <span>{bannerData.alertMessage}</span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
					);
				} else {
					return null;
				};
			}
		}
  	</AlertBannerContext.Consumer>
);

export {AlertBannerContext, AlertBannerProvider, AlertBannerRoot};