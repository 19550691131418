import { useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { ModalContext } from '../../common/modal/context';

const analyticsBase = ({ history }) => { 
    const Modal = useContext(ModalContext);

    useEffect(() => {
        //console.log('> Router', history.action, history.location);
        //clear modals if path changes.
        Modal.closeModal();
    }, [history.location.key]);

  return null;
}

export default withRouter(analyticsBase);