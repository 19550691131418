import React, { useState, createContext} from 'react';

export const AlertBannerContext = createContext(null);

const defaultBannerSettings = {
        showBanner: false,
        alertType: '',
        alertMessage: ''
    };

export const AlertBannerProvider = ({children}) => {

    const setAlertBanner = ({alertType = 'success', alertMessage = 'success'}) => {
        
        setAlertBannerState({
            showBanner: true,
            alertType: alertType,
            alertMessage: alertMessage
        });

        setTimeout( () => {
            setAlertBannerState(defaultBannerSettings)
        } , 5000);
        
    }

    const [alertBannerState, setAlertBannerState] = useState(defaultBannerSettings);

    return(
        <AlertBannerContext.Provider value={ {bannerData: alertBannerState, setAlertBanner: setAlertBanner} }>
            {children}
        </AlertBannerContext.Provider>
    )
}
