import React from 'react';
import './loading.scss';

export const LoadingComponent = () => (
    <div className="loading-background">
        <div className="spinner">
            <div className="spinner-border spinner-icon text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    </div>
);
