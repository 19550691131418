// Initialize Firebase
import app from 'firebase/app';
import 'firebase/auth';

const config = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN
};

class Firebase {
	constructor() {
		app.initializeApp(config);

		this.auth = app.auth();
		this.auth.setPersistence('local');
		this.token = '';

		this.googleProvider = new app.auth.GoogleAuthProvider();
	}

	// *** Auth API ***

	doSignInWithEmailAndPassword = (email, password) => {
		return this.auth.signInWithEmailAndPassword(email, password);
	}

  	doSignInWithGoogle = () =>
    	this.auth.signInWithRedirect(this.googleProvider);

	doSendEmailVerification = () => {
		let currentUser = this.auth.currentUser;
		currentUser.sendEmailVerification()
		.catch( error => console.log(`could not send out email: ${error}`))
	}

	doSignOut = () => this.auth.signOut();

	doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

	doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

}

export default Firebase;