import React, {useState, useEffect, useContext} from 'react';

import AuthUserContext from './context';
import { FirebaseContext } from '../firebase';
import {BaseServiceContext} from '../baseServiceCall';
import {ShoWriterAPIContext} from '../shoWriterAPI';

//warpped in contextProvider.js 
//Call through use context AuthUserContext
const WithAuthenticationProvider = ({children}) => {

    const [authState, setAuthState] = useState(null);
    const firebase = useContext(FirebaseContext);
    const baseService = useContext(BaseServiceContext);
    const ShoWriterAPI = useContext(ShoWriterAPIContext);

    const [targetRoute, setTargetRoute] = useState(null);

    useEffect( () => {
       firebase.auth.onAuthStateChanged( async authUser => {
            if ( authUser ) {
                await baseService.setToken();
                let data = await ShoWriterAPI.getUserDetail();
                if (data.data) {
                    setAuthState({
                        uid: data.data.uid,
                        userType: data.data.userType,
                        userName: data.data.userName,
                        userEmail: data.data.userEmail,
                        userFullName: data.data.userFullName,
                        initWithOAuth: data.data.initWithOAuth
                    });
                } else {
                    setAuthState( {uid : null} );
                }
            } else {
                setAuthState( null );
            }
        });

    }, [] );

    const reAuthenticate = async () => {
        let data = await ShoWriterAPI.getUserDetail();
        if (data.data) {
            setAuthState({
                uid: data.data.uid,
                userType: data.data.userType,
                userName: data.data.userName
            });
        }
    }

    const values = {
        creds:authState,
        targetRoute: targetRoute,
        setTargetRoute: setTargetRoute,
        reAuthenticate: reAuthenticate
    }

    return(
        <AuthUserContext.Provider value={values}>
            {children}
        </AuthUserContext.Provider>
    )
}

export default WithAuthenticationProvider;