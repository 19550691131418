import React from 'react';

import baseService from './baseService';

const BaseServiceContext = React.createContext(null);

const BaseServiceProvider = ({children}) => { 
	return(
        <BaseServiceContext.Provider value={baseService()}>
            {children}
        </BaseServiceContext.Provider>
    )
}

export { BaseServiceContext, BaseServiceProvider };