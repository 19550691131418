import React from 'react';
import './landing.scss';
import { LANDING_CONTENT }  from '../../constants/shoWriter_constants';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes'
import logo from '../../assets/images/showriter_logo_flat.png'
import icon from '../../assets/images/showriter_icon_gold.svg'

const LandingPage = () => (
	<div id='landing' className="row">
		<h1 className="sr-only">SHOWriter Landing Page</h1>
		<div className="col-12">
			<div className="row mb-3">
				<div className="heroSegment text-block bg-secondary p-3">
					<div className="col-12 my-4">
						<img src={logo} alt="showriter logo" className="title-logo"/>
						{/* <h2 className="display-4 font-weight-bold text-white">{LANDING_CONTENT.showriter.title}</h2> */}
					</div>
					<div className="col-12 col-md-8">
						<p className="h3 font-weight-light text-white">{LANDING_CONTENT.showriter.headline}</p>
					</div>
				</div>
			</div>
			<div className="row mb-3 bg-white">
				<div className="col-12 my-5">
					<div className="text-center my-5">
						<h2 className="font-weight-bold display-4 mb-4">{LANDING_CONTENT.pitchseet.title}</h2>
						<p className="h5 font-weight-light w-75 mx-auto">{LANDING_CONTENT.pitchseet.headline}</p>
					</div>
				</div>
			</div>
			<div className="row mb-3 bg-dark text-block discoverSegment">
				<div className="col-12 my-auto">
					<div className="row text-center text-md-right">
						<div className="col-12">
							<h2 className="display-4 font-weight-bold text-white">{LANDING_CONTENT.market.title}</h2>
						</div>
						<div className="col-12 col-md-8 offset-md-4">
							<p className="h5 font-weight-light text-white">{LANDING_CONTENT.market.headline}</p>
						</div>
					</div>
				</div>
			</div>
			<div className="row mb-3 bg-danger text-block shareSegment">
				<div className="col-12 my-auto">
					<div className="row text-center text-md-left">
						<div className="col-12">
							<h2 className="display-4 font-weight-bold text-white">{LANDING_CONTENT.share.title}</h2>
						</div>
						<div className="col-12 col-md-8">
							<p className="h5 font-weight-light text-white">{LANDING_CONTENT.share.headline}</p>
						</div>
					</div>
				</div>
			</div>
			<div className="row text-block mb-3 bg-white">
				<div className="col-12">
					<div className="row h-100">
						<div className="d-none d-md-block col-md-4 m-auto">
							<img src={icon} alt="showriter icon" className="icon-image"/>
						</div>
						<div className="col-12 col-md-8 my-auto">
							<div className="text-center">
								<h2 className="display-4 mb-4">{LANDING_CONTENT.signUp.title}</h2>
								<div className="col-12 col-md-6 offset-md-3">
									<Link to={ROUTES.SIGN_UP} className="btn btn-lg btn-block btn-primary">{LANDING_CONTENT.signUp.headline}</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default LandingPage;
