
export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const PROFILE_DEFAULT = '/profile';
export const PROFILE = '/profile/:userName?';
export const PROFILE_BASE = '/profile/';
export const ACCOUNT = '/account';
export const DASHBOARD = '/dashboard';
export const SCRIPTS = '/scripts';
export const SCRIPTEDITPAGE = '/scripts/:type/:docId?';
export const PITCHSHEET = '/pitchsheet/:docId?';
export const PITCHSHEET_BASE = '/pitchsheet/';
export const PASSWORD_RESET = '/request-password-reset'
export const SEARCH = '/search';
export const EVENTS = '/events';
export const PRIVACY = '/privacy';
export const TERMS = '/terms';
export const FAQ = '/faq';
