const dotenv = require('dotenv');
dotenv.config();

export const COMPANY = {
    title: "SHOWriter"
}  

export const AUTH_PAGE = {
    password: 'Password must be at least 8 characters and contain at least:\r\none capitalize letter,\r\none lowercase letter,\r\none number and one special character.'
}

export const STATIC_URL = {
    pitchsheetUrl: `${process.env.REACT_APP_BASE_URL}/pitchsheet/`,
    profileUrl: `${process.env.REACT_APP_BASE_URL}/profile/`,
    showriterUrl: `${process.env.REACT_APP_BASE_URL}`,
    showriterUrlDisplay: "www.showriter.com",
}

export const PAGE_NOT_FOUND = {
    title: "Pitch Sheet not visible",
    description: "Click to continue",
    emailMessage: "If you see this message, click here to let us know what went wrong",
    mailTo: "mailto:help@showriter.com",
}

export const LANDING_CONTENT = {
    showriter: {
        title: "SHOWriter",
        headline: "Showcase and Pitch your Script Concepts."
    },
    pitchseet: {
        title: "Pitch Sheet Generator",
        headline: "Create Pitch Sheets to easily share with Industry professionals."
    },
    market: {
        title: "Be discovered",
        headline: "Showcase your work and connect with others in the media and entertainment industry."
    },
    share: {
        title: "Manage how you share",
        headline: "Share with who you want, how you want. You are in control with SHOWriter."
    },
    signUp: {
        title: "Showcase yourself today.",
        headline: "Get started"
    }
}

export const FOOTER_CONTENT = {
    facebook: "https://www.facebook.com/showriterhelp/",
    twitter: "https://www.twitter.com/showriterhelp/",
    linkedIn: "https://www.linkedin.com/in/showriter",
    instagram: "https://www.instagram.com/showriterhelp/"
}

export const SOCIAL_SHARE = {
    profile_message: "Promote Your Profile:",
    profile_cta: "Check out my writer profile",
    profile_email_body: "Take a look at my full writing profile at SHOWriter.com: ",
    profile_twitter_hashtags: ["showriter", "writer", "movie", "hollywood", "film", "script"],
    profile_facebook_hashtag: "#writer",
    pitchsheet_message: "Promote Your Pitch Sheet:",
    pitchsheet_cta: "I have been working on this concept",
    pitchsheet_email_body: "Take a look at this concept I have been working on. Viewable at SHOWriter.com: ",
    pitchsheet_twitter_hashtags: ["showriter", "writer", "movie", "hollywood", "film", "script"],
    pitchsheet_facebook_hashtag: "#concept",
}

export const PROFILE_CONTENT = {
    modalState: {
        general: { 
            type: "HEADER",
            title: "Edit General Information"
        },
        experience: {
            type: "experience",
            title: "Edit Experience Information"
        },
        education: {
            type: "education",
            title: "Edit Education Information"
        }
    },
    firstName: "First Name",
    lastName: "Last Name",
    location: "Location",
    headline: "Headline",
    showHeadline: "Show headline",
    employer: "Employer",
    position: "Position",
    job_description: "Job description",
    school: "School",
    field_of_study: "Field of study",
    description: "Description",
    title: "Title",
    timeSpan: "Start and End Date",
}

export const SCRIPTS_CONTENT = {
    authorName: { 
        title: "Author",
        key: "authorName"
    },
    additionalAuthors: { 
        title: "Additional Author(s)",
        key: "additionalAuthors",
        placeholder: "Add Authors using their email address"
    },
    title: {
        title: "Title",
        key: "title"
    },
    pictureWord: {
        title: "Poster Image",
        key: "pictureWord",
        placeholder: "Search for an Image"
    },
    titleColor: {
        title: "Title Color",
        key: "titleColor",
        placeholder: "Poster Title Color"
    },
    view: {
        title: "View",
        key: "view",
        placeholder: "Who can view this concept?"
    },
    genre: {
        title: "Genre",
        key: "genre",
        placeholder: "Select Genre"
    },
    conceptType: {
        title: "Type",
        key: "conceptType",
        placeholder: "Select concept type"
    },
    filmLength: {
        title: "Film Length",
        key: "filmLength",
        placeholder: "Select Length"
    },
    budget: {
        title: "Budget",
        key: "budget",
        placeholder: "Select Budget"
    },
    numberOfChars: {
        title: "Number Of Characters",
        key: "numberOfChars",
        placeholder: "Select Number Of Characters"
    },
    numberOfLocs: {
        title: "Number Of Location",
        key: "numberOfLocs",
        placeholder: "Select Number Of Locations"
    },
    logLine: {
        title: "Log Line",
        key: "logLine",
        placeholder: "Select Number Of Locations"
    },
    synopsis: {
        title: "Synopsis",
        key: "synopsis",
        placeholder: "Select Number Of Locations"
    },
    contact: {
        title: "Contact",
        key: "contact",
        placeholder: "Best Contact Info"
    },

}