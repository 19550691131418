import {useState, useContext} from 'react';

import {ShoWriterAPIContext} from '../shoWriterAPI';
import {AlertBannerContext} from '../alertBanner';

//  Datasource to pull all of user's Profile Info
const UserProfileDataSource = () => {
    const ShoWriterAPI = useContext(ShoWriterAPIContext);
    const AlertBanner = useContext(AlertBannerContext);
    const [userProfileData, setUserProfileData] = useState(null);

    const getUserProfile = (userId) => {
		ShoWriterAPI.getuserprofile( userId )
		.then(
			(doc) => {
				const userId = doc.data.userId;
				let userProfile = doc.data;
				ShoWriterAPI.getalluserscriptsposters(userId)
				.then(
					response => {	
						userProfile.posterArray = response.data;
						setUserProfileData( userProfile );
					})
			})
        .catch(
            () => {
                AlertBanner.setAlertBanner({
                        alertType: 'danger',
                        alertMessage: 'Unable to find user.'
                    });
            }
        );
	}

    const updateUserProfile = (data) => {
        const promise = ShoWriterAPI.updateuserprofile(data);
        
        promise.then(
            AlertBanner.setAlertBanner({
                        alertType: 'success',
                        alertMessage: 'Successfully updated Profile.'
                    })
        ).catch( () => {
                AlertBanner.setAlertBanner({
                        alertType: 'danger',
                        alertMessage: 'Unable to update user information.'
                    });
            });

        return promise;
    }

    const updateField = ( key, data) => {
        userProfileData[key] = data;
        setUserProfileData({...userProfileData});
    }

    return {
        userProfileData: userProfileData,
        getUserProfile: getUserProfile,
        updateField: updateField,
        updateUserProfile: updateUserProfile,
    };
}

export default UserProfileDataSource;