import React from 'react';

import {FirebaseProvider} from './firebase';
import {BaseServiceProvider} from './baseServiceCall';
import {ShoWriterAPIProvider} from './shoWriterAPI';
import {WithAuthenticationProvider} from './session';
import {BrowserRouter as Router} from 'react-router-dom';
import {ModalProvider} from './modal';
import {AlertBannerProvider} from './alertBanner';
import {LoadingProvider} from './loadingScreen';
import {UserScriptDataSourceProvider} from './userScriptDataSource';
import {UserProfileDataSourceProvider} from './userProfileDataSource';
import {UserSearchServiceProvider} from './userSearchService';

const ContextProvider = ({children}) => {
    return (
        <FirebaseProvider>
            <LoadingProvider>
                <AlertBannerProvider>
                    <Router>
                        <BaseServiceProvider>
                            <ShoWriterAPIProvider>
                                <WithAuthenticationProvider>
                                    <UserScriptDataSourceProvider>
                                        <UserProfileDataSourceProvider>
                                            <UserSearchServiceProvider>
                                                <ModalProvider>
                                                    {children}
                                                </ModalProvider>
                                            </UserSearchServiceProvider>
                                        </UserProfileDataSourceProvider>
                                    </UserScriptDataSourceProvider>
                                </WithAuthenticationProvider>
                            </ShoWriterAPIProvider>
                        </BaseServiceProvider> 
                    </Router>
                </AlertBannerProvider>
            </LoadingProvider>
        </FirebaseProvider>
    )
}

export default ContextProvider;