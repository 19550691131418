import React from 'react';
import './modal.scss';

import { ModalContext, ModalProvider } from './context';

const ModalRoot = () => (
  	<ModalContext.Consumer>
		{({ component: Component, config, closeModal, backdropClose }) => 
			{
				if (Component) { 
					return(
						<div className="ss-modal" onClick={ config.backdropClose && backdropClose }>
							<div className={`ss-modal-content ${config.size}`}>
								<div className="ss-modal-header d-print-none">
									<div className="ss-modal-header-title">{config.title}</div>
									<span className="ss-modal-close-btn"
										onClick={ closeModal }>
										<i className="ss-modal-close-icon material-icons">close</i>
									</span>
								</div>
								<div className="ss-modal-body">
									<Component {...config} closeModal={closeModal} />
								</div>
								<div className="ss-modal-footer d-print-none"></div>
							</div>
						</div>
					);
				} else {
					return null;
				};
			}
		}
  	</ModalContext.Consumer>
);

export {ModalContext, ModalProvider, ModalRoot};