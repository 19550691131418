import React, { useState, createContext} from 'react';
import {LoadingComponent} from './loading.component';

const LoadingContext = createContext(null);

const LoadingProvider = ({children}) => {

    const showLoadingScreen = () => {
        setLoadingState(true);
    };

    const closeLoadingScreen = () => {
        setLoadingState(false);
    };

    const [loadingState, setLoadingState] = useState(false);

    return(
        <LoadingContext.Provider value={ {loadingState: loadingState, showLoadingScreen:showLoadingScreen, closeLoadingScreen:closeLoadingScreen} }>
            {children}
        </LoadingContext.Provider>
    )
}

const LoadingRoot = () => (
  	<LoadingContext.Consumer>
		{ ( {loadingState} ) => 
			{
				if (loadingState) {
                    return (
                        <LoadingComponent/>
                    );
                }
			}
		}
  	</LoadingContext.Consumer>
);

export {LoadingRoot, LoadingProvider, LoadingContext, LoadingComponent};
