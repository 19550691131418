import React from 'react';

import UserProfileDataSource from './userProfileDataSource';

const UserProfileDataSourceContext = React.createContext(null);

const UserProfileDataSourceProvider = ({children}) => { 
	return(
        <UserProfileDataSourceContext.Provider value={UserProfileDataSource()}>
            {children}
        </UserProfileDataSourceContext.Provider>
    )
}

export { UserProfileDataSourceContext, UserProfileDataSourceProvider };