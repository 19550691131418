import React from 'react';
import './footer.scss'; 
import { FOOTER_CONTENT }  from '../../constants/shoWriter_constants';
import * as ROUTES from '../../constants/routes';
import { Link } from 'react-router-dom';

import fLogo from '../../assets/images/icons/f-ogo_RGB_HEX-100.svg';
import tLogo from '../../assets/images/icons/Twitter_Social_Icon_Rounded_Square_Color.svg';
import instLogo from '../../assets/images/icons/glyph-logo_May2016.png';

const Footer = () => (
    <div id='footer' className="p-3 d-print-none">
        <div className="row">
            <div className="col-12 border-top">
                <div className="row text-center text-md-left">
                    <div className="col-12 mt-4">
                        <span className="d-block d-md-inline-block mb-2">
                            <Link className="mr-md-4 text-dark" target="_blank" to={ROUTES.PRIVACY}>Privacy Policy</Link>
                        </span>
                        <span className="d-block d-md-inline-block mb-2">
                            <Link className="mr-md-4 text-dark" target="_blank" to={ROUTES.TERMS}>Terms and Conditions</Link>
                        </span>
                        <span className="d-block d-md-inline-block mb-2">
                            <Link className="mr-md-4 text-dark" target="_blank" to={ROUTES.FAQ}>FAQ</Link>
                        </span>
                        <span className="d-block d-md-inline-block">
                            <a className="text-dark" href="mailto:help@showriter.com">Contact Us</a>
                        </span>
                    </div>
                </div>
            </div>
            <div className="border-top mt-4 mb-3 w-100 d-md-none"></div>
            <div className="col-12">
                <div className="row">
                    <div className="col-12 col-md-9 mb-3 mb-md-0 text-center text-md-left">
                        <p className="m-0 mt-2">&#169; 2020 SHOWriter.com</p>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="text-center text-md-right">
                            <div className="d-inline-block mr-2">
                                <a href={ FOOTER_CONTENT.facebook } target="_blank" rel="noopener noreferrer">
                                    <img className="icon" src={ fLogo } alt="facebook"/>
                                </a>
                            </div>
                            <div className="d-inline-block mr-2">
                                <a href={ FOOTER_CONTENT.twitter } target="_blank" rel="noopener noreferrer">
                                    <img className="icon" src={ tLogo } alt="twitter"/>
                                </a>
                            </div>
                            <div className="d-inline-block instagram-background">
                                <a href={ FOOTER_CONTENT.instagram } target="_blank" rel="noopener noreferrer">
                                    <img className="icon instagram-icon" src={ instLogo } alt="instagram"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Footer;

