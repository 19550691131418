import {useState, useContext} from 'react';

import {ShoWriterAPIContext} from '../shoWriterAPI';

//  Datasource to pull all of user's scripts
const UserScriptDataSource = () => {
    const ShoWriterAPI = useContext(ShoWriterAPIContext);
    const [userScriptData, setUserScriptData] = useState(null);
    const [linkedUserScriptData, setLinkedUserScriptData] = useState([]);
    const [updates, setUpdates] = useState(null);

    const getUserScripts = () => {
        ShoWriterAPI.getownscripts()
            .then(response => {
                setUserScriptData(response.data.concepts);
                setLinkedUserScriptData(response.data.linked_concepts);
            })
            .catch( error => {
                console.log('Unable to find User Script', error);
            });
    }

    const getScriptData = (docId, isLinkedConcepts) => {
        if (isLinkedConcepts) {
            return linkedUserScriptData.find(script => script.docId === docId); 
        } else {
            return userScriptData.find(script => script.docId === docId);
        }
    }

    const updateScript = (docId) => {
        ShoWriterAPI.getscript(docId)
            .then(response => {
                const index = userScriptData.findIndex(script => script.docId === docId);
                response.data.docId = docId;
                userScriptData[index] = response.data;
                setUserScriptData([...userScriptData]);
            })
            .catch( error => {
                console.log('Unable to update UserScriptDataSource:', error);
            });
    }

    const deleteScript = (docId) => {
        const index = userScriptData.findIndex(script => script.docId === docId);
        userScriptData.splice(index,1);
        setUserScriptData([...userScriptData]);
    }

    const toggleProfileVisiblity = (docId, bool, isLinkedConcepts, uid) => {
        ShoWriterAPI.updateshowinpublicprofile(docId, bool)
            .then(response => {
                let index;
                if (isLinkedConcepts) {
                    index = linkedUserScriptData.findIndex(script => script.docId === docId);
                    let authorIndex = linkedUserScriptData[index].additionalAuthors.findIndex(authorObj => authorObj.userId === uid);
                    linkedUserScriptData[index].additionalAuthors[authorIndex].showCase = bool;
                    setLinkedUserScriptData([...linkedUserScriptData]);
                } else {
                    index = userScriptData.findIndex(script => script.docId === docId);
                    userScriptData[index].showInPublicProfile = bool;
                    setUserScriptData([...userScriptData]);
                }
            })
            .catch( error => {
                console.log('Unable to update script profile visibility.');
            });
    }

    return {
        getUserScripts: getUserScripts,
        userScriptData: userScriptData,
        linkedUserScriptData: linkedUserScriptData,
        getScriptData: getScriptData,
        updateScript: updateScript,
        deleteScript: deleteScript,
        toggleProfileVisiblity: toggleProfileVisiblity,
        updates: updates, 
        setUpdates: setUpdates
    };
}

export default UserScriptDataSource;