import {useState, useContext} from 'react';

import {ShoWriterAPIContext} from '../shoWriterAPI';
import {AlertBannerContext} from '../alertBanner';


const UserSearchService = () => {
    const ShoWriterAPI = useContext(ShoWriterAPIContext);
    const AlertBanner = useContext(AlertBannerContext);

    const [searchResults, setSearchResults] = useState({});
    const [searchQuery, setSearchQuery] = useState(null);
    const [previousPagination, setPreviousPagination] = useState([0]);
    const [searchWords, setSearchWords] = useState([]);

    const getsearchwords = () => {
        if (searchWords.length) {
            return;
        }
        ShoWriterAPI.getsearchwords()
            .then( response => {
                setSearchWords(response.data.wordsArray);
            })
            .catch(
                () => {
                    setSearchWords([])
                    AlertBanner.setAlertBanner({
                            alertType: 'danger',
                            alertMessage: 'Unable to complete search.'
                        });
                }
            );
    }

    const submitSearchQuery = ( query ) => {
		ShoWriterAPI.searchScript( query )
            .then( response => {
                setSearchQuery(query);
                setSearchResults(response.data);
                setPreviousPagination( [0] );
                }
            )
            .catch(
                () => {
                    AlertBanner.setAlertBanner({
                            alertType: 'danger',
                            alertMessage: 'Unable to complete search.'
                        });
                }
            );
	}

    const submitNextQuery = () => {
        if (!searchQuery || !searchResults.next ){
            return;
        }

        const payload = { ...searchQuery, milliseconds: searchResults.next};

        ShoWriterAPI.searchScript( payload )
            .then( response => {
                const paginationArray = [ ...previousPagination];
                paginationArray.push(searchResults.next);

                setPreviousPagination( [...paginationArray] );
                setSearchResults(response.data);
                }
            ).catch(
                () => {
                    AlertBanner.setAlertBanner({
                            alertType: 'danger',
                            alertMessage: 'Unable to complete search.'
                        });
                }
            );
    }

    const submitPreviousQuery = () => {
        if (!searchQuery){
            return;
        }

        let milliseconds = 0;
        const paginationArray = [ ...previousPagination];

        if ( paginationArray.length > 1) {
            milliseconds = paginationArray[paginationArray.length-2];
            paginationArray.pop();
        } else {
            return;
        }

        const payload = { ...searchQuery, milliseconds: milliseconds};

        ShoWriterAPI.searchScript( payload )
            .then( response => {
                setPreviousPagination([...paginationArray]);
                setSearchResults(response.data);
                }
            )
            .catch(
                () => {
                    AlertBanner.setAlertBanner({
                            alertType: 'danger',
                            alertMessage: 'Unable to complete search.'
                        });
                }
            );
    }

    return {
        submitSearchQuery: submitSearchQuery,
        submitNextQuery: submitNextQuery,
        submitPreviousQuery: submitPreviousQuery,
        searchResults: searchResults.queryArray,
        isPrevious: previousPagination.length > 1,
        isNext: !!searchResults.next,
        searchWords: searchWords,
        getsearchwords: getsearchwords
    };
}

export default UserSearchService;