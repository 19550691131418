import React, {useState, useEffect, useContext} from 'react';
import { useHistory } from 'react-router-dom';

import { FirebaseContext } from '../firebase';
import AuthUserContext from './context';
import * as ROUTES from '../../constants/routes';

//takes in a conditional function and then the component to render.
const withAuthorization = condition => Component => {

    const withAuthBase = (props) => {
        const firebase = useContext(FirebaseContext);
        const auth = useContext(AuthUserContext);
        const history = useHistory();
        const [ authComplete, setAuthComplete ] = useState(false);

        useEffect( () => {
            const unSubscribe = firebase.auth.onAuthStateChanged(
                user => {
                    // this bypass works for now but there is an issue with firebase token not updating quickly enough for the api to recieve the token.
                    setAuthComplete(true);
                    if (!user && condition) {
                        const previousPath = history.location.pathname;
                        if (previousPath !== '/signin') {
                            auth.setTargetRoute(previousPath);
                            history.push(ROUTES.SIGN_IN);
                        }
                    }
                }
            );

            return () => { unSubscribe() }
        }, []);

        return (
            <React.Fragment>
                {condition ? condition(auth.creds) && <Component {...props} /> : authComplete && <Component {...props} />}
                {auth.creds && condition && !condition(auth.creds) && 'ACCESS DENIED'}
            </React.Fragment>
        );
    }

    return withAuthBase;
};

export default withAuthorization;
