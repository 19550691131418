import React from 'react';

import {shoWriterAPIService} from './shoWriterAPIService';

const ShoWriterAPIContext = React.createContext(null);

const ShoWriterAPIProvider = ({children}) => { 
	return(
        <ShoWriterAPIContext.Provider value={shoWriterAPIService()}>
            {children}
        </ShoWriterAPIContext.Provider>
    )
}

export { ShoWriterAPIContext, ShoWriterAPIProvider };