import React from 'react';
import { withRouter } from 'react-router-dom';

import { withFirebase } from '../../common/firebase';


const SignOutButton = () => (
  <SignOut />
)

const SignOutBase = ( props ) => {

  var firebaseLogout = (event) => {
    props.firebase.doSignOut()
    event.preventDefault();
  }

  firebaseLogout = firebaseLogout.bind(this);

  return (
    <a id="signOut" href="#signOut" className="nav-link" onClick={ firebaseLogout }>
      Sign Out
    </a>
  )
};

const SignOut = withRouter(withFirebase( SignOutBase ));

export default SignOutButton;