import React from 'react';

import UserSearchService from './userSearchService';

const UserSearchServiceContext = React.createContext(null);

const UserSearchServiceProvider = ({children}) => { 
	return(
        <UserSearchServiceContext.Provider value={UserSearchService()}>
            {children}
        </UserSearchServiceContext.Provider>
    )
}

export { UserSearchServiceContext, UserSearchServiceProvider };