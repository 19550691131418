import {useContext} from 'react';
import {BaseServiceContext} from '../baseServiceCall';


export const shoWriterAPIService = () => {
    const baseService = useContext(BaseServiceContext);

    return {
        getownscripts: () => {
            const config = {
                URL: '/api/scripts/getownscripts',
                method: 'get',
                loading_screen: true,
                headers: true
            }
            return baseService.CALL(config);
        },
        getsearchwords: () => {
            const config = {
                URL: `/api/scripts/getsearchwords`,
                method: 'get',
                loading_screen: false,
                headers: true
            }

            return baseService.CALL(config);
        },
        getalluserscriptsposters: (userId) => {
            //set up cacheKey here if necessary
            const config = {
                URL: `/api/scripts/getalluserscriptsposters/${userId}`,
                method: 'get',
                loading_screen: true,
                headers: false
            }

            return baseService.CALL(config);
        },
        getscript: (docId) => {
            const config = {
                URL: `/api/scripts/getscript`,
                method: 'post',
                loading_screen: true,
                headers: true
            }

            return baseService.CALL(config, {docId});
        },
        createscript: (script) => {
            const config = {
                URL: `/api/scripts/createscript`,
                method: 'post',
                loading_screen: true,
                headers: true
            }

            return baseService.CALL(config, script);
        },
        updatescript: (docId, docFields) => {
            delete docFields.docId;

            const config = {
                URL: `/api/scripts/updatescript`,
                method: 'post',
                loading_screen: true,
                headers: true
            }

            return baseService.CALL(config, {docId, docFields});
        },
        updateviewscript: (docId, view) => {
            const config = {
                URL: `/api/scripts/updateviewscript`,
                method: 'post',
                loading_screen: true,
                headers: true
            }

            return baseService.CALL(config, {docId, view});
        },
        deletescript: (docId) => {
            const config = {
                URL: `/api/scripts/deletescript`,
                method: 'post',
                loading_screen: true,
                headers: true
            }

            return baseService.CALL(config, {docId});
        },
        getPitchSheet: (docId) => {
            const config = {
                URL: `/api/scripts/getpitchsheet/${docId}`,
                method: 'get',
                loading_screen: true,
                headers: true
            }

            return baseService.CALL(config);
        },
        searchScript: (searchCriteria) => {
          const config = {
                URL: `/api/scripts/searchcategories`,
                method: 'post',
                loading_screen: true,
                headers: true
            }

            return baseService.CALL(config, searchCriteria);
        },
        updateshowinpublicprofile: (docId, showInPublicProfile) => {
            const config = {
                URL: `/api/scripts/updateshowinpublicprofile`,
                method: 'post',
                loading_screen: true,
                headers: true
            }

            return baseService.CALL(config, {docId, showInPublicProfile});
        },
        getuserprofile: (param) => {
            const config = {
                URL: `/api/users/getuserprofile/${param}`,
                method: 'get',
                loading_screen: true,
                headers: false
            }

            return baseService.CALL(config);
        },
        createuserprofile: (data) => {
            const config = {
                URL: `/api/users/createuserprofile`,
                method: 'post',
                loading_screen: true,
                headers: false
            }

            return baseService.CALL(config, data);
        },
        createUserFromOAuth: (data) => {
            const config = {
                URL: `/api/users/createuserfromoauth`,
                method: 'post',
                loading_screen: true,
                headers: true
            }

            return baseService.CALL(config, data);
        },
        updateuserprofile: (data) => {
            const config = {
                URL: `/api/users/updateuserprofile`,
                method: 'post',
                loading_screen: true,
                headers: true
            }

            return baseService.CALL(config, data);
        },
        uploadProfileImage: (img) => {
            const config = {
                URL: `/api/users/uploadprofilepicture`,
                method: 'post',
                loading_screen: true,
                headers: true
            }

            return baseService.CALL(config, img);
        },
        createPrivateUrl: (docId) => {
            const config = {
                URL: `/api/scripts/createprivateurl/${docId}`,
                method: 'get',
                loading_screen: true,
                headers: true
            }

            return baseService.CALL(config);
        },
        deletePrivateUrl: (docId) => {
            const config = {
                URL: `/api/scripts/deleteprivateurl/${docId}`,
                method: 'get',
                loading_screen: true,
                headers: true
            }

            return baseService.CALL(config);
        },
        getUnsplashImages: (searchWord) => {
             const config = {
                URL: `/api/images/getimages/${searchWord}`,
                method: 'get',
                loading_screen: true,
                headers: true
            }

            return baseService.CALL(config);
        },
        getUserDetail: () => {
             const config = {
                URL: `/api/users/getuserdetail`,
                method: 'get',
                loading_screen: false,
                headers: true
            }

            return baseService.CALL(config);
        },
        searchUserByProfileName: (data) => {
             const config = {
                URL: `/api/users/searchuser`,
                method: 'post',
                loading_screen: true,
                headers: true
            }

            return baseService.CALL(config, data);
        },
        searchUserByEmail: (data) => {
             const config = {
                URL: `/api/users/searchuseremail`,
                method: 'post',
                loading_screen: true,
                headers: true
            }

            return baseService.CALL(config, data);
        }
    }
};
