import React, { useState, useEffect, useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';

import SignOutButton from '../signOut';
import * as ROUTES from '../../constants/routes';
import { ROLES } from '../../constants/roles';
import logo from '../../assets/images/showriter_logo_flat.png'

import { AuthUserContext } from '../../common/session';

import './navigationStyle.scss';

const NavigationBar = ({ history }) => {
	const [menuState, setMenuState] = useState(false);

	const [betaBanner, setBetaBanner] = useState(true);
	const toggleBanner = () => setBetaBanner(false);

	const toggleMenu = () => { setMenuState(!menuState) };

	useEffect(() => {
		setMenuState(false);
    }, [history.location.key]);

	const classDropdownMenu = 'collapse navbar-collapse' + (menuState ? ' show' : '');
	const navLinks = menuState ? 'navbar-brand navbar-nav text-center' : 'ml-auto navbar-nav';

	return(
		<div className="mb-3 d-print-none">
			<nav id='navigationBar' className="navbar fixed-top navbar-expand-md py-1 navbar-dark bg-dark">
					<Link to={ROUTES.LANDING}>
						<img src={logo} alt="showriter logo" className="banner-logo-img"/>
					</Link>
					<button className="navbar-toggler" type="button" onClick={toggleMenu}>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className={classDropdownMenu} >
						<NavigationBase navLinkClass={navLinks}/>
					</div>
			</nav>
			{/* To Be Removed after Full Release */}
			<div className="row justify-content-center">
				<div className="col-10">
					<div className={`alert alert-info alert-dismissible m-0 ${betaBanner ? 'show' : 'd-none'}`} role="alert">
						<p className="m-0">BETA: Your <a href="mailto:help@showriter.com" className="alert-link">feedback</a> is encouraged.  <a href="/signup" className="text-body"><i className="alert-link">Free to join!</i></a></p>
						<button type="button" className="close" aria-label="Close" data-dismiss="alert" onClick={toggleBanner}>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

const NavigationBase = props => {

	const auth = useContext(AuthUserContext);
	let navBar;
	
	if(auth.creds){

		switch(auth.creds.userType){
			case ROLES.writer:
				navBar =  <NavigationWriterAuth userName={auth.creds.userName} navClass={props.navLinkClass}/>;
				break;
			case ROLES.producer:
				navBar =  <NavigationProducerAuth userName={auth.creds.userName} navClass={props.navLinkClass}/>;
				break;
			case ROLES.admin:
				break;
			default:
				navBar =  <NavigationNonAuth navClass={props.navLinkClass}/>;
		}
		
	}else{
		navBar =  <NavigationNonAuth navClass={props.navLinkClass}/>;
	}

	return( navBar );
}

const NavigationWriterAuth = props => (
	<ul className={props.navClass}>
		{/* <li className="sidenav-close">
			<Link to={ROUTES.HOME} >Home</Link>
		</li> */}
		<li className="nav-item">
			<Link className="nav-link" to={ROUTES.SEARCH} >Search</Link>
		</li>
		<li className="nav-item">
			<Link className="nav-link" to={ROUTES.SCRIPTS} >My Concepts</Link>
		</li>
		<li className="nav-item">
			<Link className="nav-link" to={`${ROUTES.PROFILE_DEFAULT}/${props.userName}`} >My Profile</Link>
		</li>
		<li className="nav-item">
			<Link className="nav-link" to={`${ROUTES.ACCOUNT}`} >My Account</Link>
		</li>
		{/* <li className="sidenav-close">
			<Link to={ROUTES.DASHBOARD} >Dashboard</Link>
		</li> */}
		<li className="nav-item">
			<SignOutButton />
		</li>
	</ul>
);

const NavigationProducerAuth = props => (
	<ul className={props.navClass}>
		<li className="nav-item">
			<Link className="nav-link" to={ROUTES.HOME} >Home</Link>
		</li>
		<li className="nav-item">
			<Link className="nav-link" to={ROUTES.SEARCH} >Search</Link>
		</li>
		{/* <li className="nav-item">
			<Link className="nav-link" to={ROUTES.EVENTS} >Events</Link>
		</li> */}
		<li className="nav-item">
			<Link className="nav-link" to={`${ROUTES.PROFILE_DEFAULT}/${props.userName}`} >My Profile</Link>
		</li>
		{/* <li className="nav-item">
			<Link className="nav-link" to={ROUTES.DASHBOARD} >Dashboard</Link>
		</li> */}
		<li className="nav-item">
			<SignOutButton/>
		</li>
	</ul>
);

const NavigationNonAuth = props => (
  <ul className={props.navClass}>
		<li className="nav-item">
			<Link className="nav-link" to={ROUTES.SIGN_IN} >Sign In/Up</Link>
		</li>
  </ul>
);

export default  withRouter(NavigationBar);